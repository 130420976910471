<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Contrato</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons 
          :tenderId="this.tenderId"
          :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Editar Estudio
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Texto de Contrato</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Texto de Contrato</h1>
                        <trumbowyg
                            v-model="data.contrato_texto"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Texto de Contrato"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Declaraciones</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Declaraciones
                        </h1>
                        <trumbowyg
                            v-model="data.declaraciones"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Declaraciones"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Cláusulas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Cláusulas
                        </h1>
                        <trumbowyg
                            v-model="data.clausulas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Cláusulas"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Vigésima Tercera</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Vigésima Tercera
                        </h1>
                        <trumbowyg
                            v-model="data.vigesima_tercera"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Vigésima Tercera"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Fecha y Hora</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Fecha y Hora
                        </h1>
                        <trumbowyg
                            v-model="data.fecha_hora"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Fecha y Hora"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Pie de Licitante</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Pie de Licitante
                        </h1>
                        <trumbowyg
                            v-model="data.pie_licitante"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Pie de Licitante"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" lg="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="12" lg="6" class="mt-6">
                  <v-btn type="button" @click="generateData" color="primary" style="width: 200px">
                    Generar archivo
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatus" @click="downloadFileServer" color="primary" style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatus">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerate"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'

export default {
  name: "DetailContract",
  data() {
    return {
      overlay: false,
      tenderId: null,
      externalId: null,
      documentComplete:null,
      documentGenerate:null,
      documentGenerateStatus:false,
      id: null,
      formatosId:null,
      data: {
        contrato_texto: "",
        vigesima_tercera: "",
        declaraciones: "",
        clausulas: '',
        fecha_hora: '',
        pie_licitante: '',
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    CheckButtons,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('contract', ['updateContract', 'findOneContract','generateHtmlFormat','downloadFile']),
    ...mapActions('tenders', ['findOneTenders']),
    async findMarketOne() {
      const response = await this.findOneContract({id: this.id, tenderId: this.tenderId});
      if (response) {
        this.documentComplete = response.documentoCompleto
        this.data.formatData = response.formatoData;
        this.data.contrato_texto = response.contrato_texto;
        this.data.declaraciones = response.declaraciones;
        this.data.clausulas = response.clausulas;
        this.data.vigesima_tercera = response.vigesima_tercera;
        this.data.fecha_hora = response.fecha_hora;
        this.data.pie_licitante = response.pie_licitante;
        
        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal){
          this.externalId = responseExternal.claveLicitacion;
        }else{
          this.externalId =null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired(){
      if (!this.data.contrato_texto){
        this.sweetAlertWarning('Campo Texto de Contrato es necesario');
        return false
      }
      if (!this.data.declaraciones){
        this.sweetAlertWarning('Campo Declaraciones es requerido');
        return false
      }
      if (!this.data.clausulas){
        this.sweetAlertWarning('Campo Clausulas es requerido');
        return false
      }
      if (!this.data.vigesima_tercera){
        this.sweetAlertWarning('Campo Vigeisima Tercera es requerido');
        return false
      }
      if (!this.data.fecha_hora){
        this.sweetAlertWarning('Campo Fecha y hora es requerido');
        return false
      }
      if (!this.data.pie_licitante){
        this.sweetAlertWarning('Campo Pie Licitante es requerido');
        return false
      }
      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();
      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.updateContract({id: this.id, data: this.data});
      if (!response.error) {
        await this.findMarketOne();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async generateData() {
      this.overlay = true;
      const response = await this.generateHtmlFormat({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFile({id: this.id,externalId: this.externalId});
      this.overlay = false;
    },
    alertNexPrograming(){
      this.sweetAlertWarning('Proximamente')
    }
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
